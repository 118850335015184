import { documentTags } from "../reduxLoop/constants/documentTags";
import oauthSettings from "./vsko-oauth-configuration-stripped.json";

export const settings = {
  angularModuleName: "VSKO Redactie Project",
  projectName: "redactie",
  projectTitle: "Redactie",
  projectVersion: "main",
  projectDescription: "A structured content editor",
  environment: "",
  enableMswInBrowser: false,
  apisAndUrls: {
    api: "https://api.katholiekonderwijs.vlaanderen",
    namedSetsApi: "https://namedsets-api.katholiekonderwijs.vlaanderen",
    securityApi: "https://beveiliging-nodejs.katholiekonderwijs.vlaanderen",
    oldSecurityApi: "https://api.katholiekonderwijs.vlaanderen",
    cachedVakkenApi: "https://cached-api.katholiekonderwijs.vlaanderen",
    myVsko: "https://mijn.katholiekonderwijs.vlaanderen",
    personsApi: "https://api.katholiekonderwijs.vlaanderen",
    contentApi: "https://content-api.katholiekonderwijs.vlaanderen",
    trainingApi: "https://training-api.katholiekonderwijs.vlaanderen",
    websitesApi: "https://content-api.katholiekonderwijs.vlaanderen",
    privateStateApi: "https://privatestate-api.katholiekonderwijs.vlaanderen",
    cachedSamenscholingApi: "https://cached-api.katholiekonderwijs.vlaanderen",
    proposalApi: "https://proposal-api.katholiekonderwijs.vlaanderen",
    mailerApi: "https://mailer-api.katholiekonderwijs.vlaanderen",
    auditApi: "http://localhost:4000",
    searchApi: "https://api.katholiekonderwijs.vlaanderen",
    zillSelector: "https://zill-selector.katholiekonderwijs.vlaanderen",
    zill: "https://zill.katholiekonderwijs.vlaanderen",
    llinkid: "http://llinkid.katholiekonderwijs.vlaanderen",
    proWebsite: "https://pro.katholiekonderwijs.vlaanderen",
    newsletterApi: "https://api.katholiekonderwijs.vlaanderen",
    newsletterPreview:
      "http://nieuwsbrief-2.katholiekonderwijs.vlaanderen/{%key}",
    cachedApi: "https://cached-api.katholiekonderwijs.vlaanderen",
  },
  domains: {
    pro: "pro.katholiekonderwijs.vlaanderen",
    www: "www.katholiekonderwijs.vlaanderen",
  },
  websites: {
    pro: "70b12dcc-724b-46b8-9ab9-b8ecdf35a0ad",
    www: "f8a0049e-d0c0-4bff-81f1-4403297395f3",
  },
  privateState: {
    component: "/security/components/redactie",
  },
  emails: {
    tagAddressMap: new Map([
      [documentTags.jobOffer, "www.eindredactie@katholiekonderwijs.vlaanderen"],
      [documentTags.newsItem, "www.eindredactie@katholiekonderwijs.vlaanderen"],
      [
        documentTags.pressRelease,
        "www.eindredactie@katholiekonderwijs.vlaanderen",
      ],
      [
        documentTags.proNewsItem,
        "pro.eindredactie@katholiekonderwijs.vlaanderen",
      ],
      [documentTags.teaser, "pro.eindredactie@katholiekonderwijs.vlaanderen"],
      [documentTags.webPage2, "pro.eindredactie@katholiekonderwijs.vlaanderen"],
      [
        documentTags.wwwContent,
        "www.eindredactie@katholiekonderwijs.vlaanderen",
      ],
      [
        documentTags.sharedMiniDatabaseItem,
        "pro.eindredactie@katholiekonderwijs.vlaanderen",
      ],
      [
        documentTags.sharedFaq,
        "pro.eindredactie@katholiekonderwijs.vlaanderen",
      ],
      [
        documentTags.sharedAttachmentsGroup,
        "pro.eindredactie@katholiekonderwijs.vlaanderen",
      ],
    ]),
    template: {
      status: "QUEUED",
      sender: {
        name: "Redactie",
        email: "redactie@katholiekonderwijs.vlaanderen",
      },
      application: {
        href: "/security/components/redactie",
      },
      globalMergeVariables: [
        {
          name: "FOOTERWEBSITE",
          content: "https://redactie.katholiekonderwijs.vlaanderen/",
        },
        {
          name: "FOOTERCONTACTURL",
        },
        {
          name: "TWITTERURL",
        },
        {
          name: "FACEBOOKURL",
        },
        {
          name: "HEADER",
          content:
            '<h1 style="font-size: 28px; font-weight:normal; font-family: arial,sans-serif; text-align: right; padding-right: 15px;">redactie</h1>',
        },
        {
          name: "TITLE",
          content: "Redactie",
        },
      ],
    },
    submitSuggestions: {
      content: {
        body: {
          href: "/mailer/templates/1122664a-0b02-45d8-80bb-d95356bcfaf0",
        },
        frame: {
          href: "/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b",
        }, // general template frame
      },
    },
    publishDocument: {
      content: {
        body: {
          href: "/mailer/templates/1122664a-0b02-45d8-80bb-d95356bcfaf0",
        },
        frame: {
          href: "/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b",
        }, // general template frame
      },
    },
  },
  samenscholing: {
    name: "SAM-ACCESSTOKEN-ACC",
    token: "bc62759b-49c6-4055-94f4-ed5850fdc1fe",
    prefix: "/sam",
  },
  resourcesNames: {
    content: "/content",
    relations: "/content/relations",
    responsibilities: "/responsibilities",
    privatestates: "/privatestates",
  },
  logging: {
    logrocket: {
      enabled: false,
      key: "ydysdg/redactie-prod-0djzk",
      users: [
        "isabelle.buyse",
        "reg.carremans",
        "marijke.demeyst",
        "jan-baptist.desmet",
        "priscilla.verhaeghen",
        "tom.uytterhoeven",
      ],
    },
    sentry: {
      enabled: true,
      url: "https://c7a3f97253c747f3995fd5dd9d4f15b6@o268751.ingest.sentry.io/1479203",
      sentryPerformanceSampleRate: 1.0,
    },
  },
  trustedOrigins: ["*"],
  oauth: oauthSettings,
  loginRequired: true,
  debug: true,
  newsHash: "6c48604b-1549-410c-9c81-5467f239800d",
  redactieSecurityComponent: "/security/components/redactie-app",
  securityModes: [
    {
      type: "UPDATE_CONTENT",
      ability: "update",
      componentHref: "/security/components/content-api",
    },
    {
      type: "REVIEW",
      ability: "review",
      componentHref: "/security/components/proposals-api",
      rawUrl: "/proposals",
      useOldSecurity: true,
    },
    {
      type: "SUGGEST",
      ability: "suggest",
      componentHref: "/security/components/proposals-api",
      rawUrl: "/proposals",
      useOldSecurity: true,
    },
    {
      type: "APPROVE",
      ability: "execute",
      componentHref: "/security/components/newsletter-api",
      rawUrl: "/newslettersender/sendwithjob",
    },
  ],
  prerender: {
    enabled: false,
    url: "https://api.prerender.io/recache",
    token: "XviHbBzWrXNJwKRYTvpa",
  },
  CLOCK_OUT_OF_SYNC_SAFETY_DELAY: 3000, // add a few seconds to lastRead on privateState/approvalDate on newsletterSettings for comparison with $$meta.modified on Content (because clock client and Content Api might be out of sync),
  defaultCollapseNodeCountTreshold: 100, // if a document contains X nodes default collapsing is enabled, below the treshold everything is uncollapsed
};
